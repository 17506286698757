<template>
  <div v-if="isImport">
    <input ref="excel-upload-input" class="excel-upload-input" type="file" accept=".xlsx, .xls, .csv" @change="handleClick">
    <CButton icon="el-icon-link" class="btn btn-success mr-3" variant='outline' @click="handleUpload">
        CSVファイルを選択
    </CButton>
    <CButton :disabled="isDisabled" class="btn btn-info" variant='outline' icon="el-icon-upload" @click="handleImport">
        データを更新する
    </CButton>
    <a v-if="exampleFile" :href="exampleFile.path" :download="exampleFile.name">
      <CButton :loading="submitting" type="primary"  class="btn btn-dark" style="margin-left: 10px" icon="el-icon-download">
          サンプルファイル
      </CButton>
    </a>
      <div v-if="loading">
          <UploadLoadingCompoment />
      </div>
  </div>
  <div v-else>
    <input ref="excel-upload-input" name="upload" class="excel-upload-input" type="file" accept=".xlsx, .xls, .csv" @change="clickSchedule">
    <CButton icon="el-icon-link" type="submit" class="btn btn-success mr-3" variant='outline' @click="handleUpload">
        CSVファイルを選択
    </CButton>
    <a v-if="exampleFile" :href="exampleFile.path" :download="exampleFile.name">
      <CButton :loading="submitting" type="primary"  class="btn btn-dark" style="margin-left: 10px" icon="el-icon-download">
          サンプルファイル
      </CButton>
    </a>
      <div v-if="loading">
          <UploadLoadingCompoment />
      </div>
  </div>
</template>

<script>

import XLSX from 'xlsx';
import Encoding from 'encoding-japanese';

export default {
  props: {
    beforeUpload: Function,
    onSuccess: Function,
    importFilename: Function,
    getString: Function,
    headerArray: {
      type: Array,
      default: []
    },
    exampleFile: {
      default: null
    },
    isImport: Boolean,
    isDisabled: Boolean
  },
  data() {
    return {
      errorArray: [],
      dialogTableVisible: false,
      loading: false,
      submitting: false,
      excelData: {
        header: null,
        results: null,
      },
    }
  },
  methods: {
    handleImport() {
      this.$emit('import')
    },
    generateData({ header, results }) {
      this.excelData.header = header
      this.excelData.results = results
      this.onSuccess && this.onSuccess(this.excelData)
      this.loading = false
    },
    handleDragover(e) {
      e.stopPropagation()
      e.preventDefault()
      e.dataTransfer.dropEffect = 'copy'
    },
    handleUpload() {
      this.$refs['excel-upload-input'].click()
    },
    async handleClick(e) {
        const files = e.target.files
        const rawFile = files[0] // only use files[0]
        if (!rawFile) return
        this.upload(rawFile)
    },
    async clickSchedule(e) {
        const files = e.target.files
        const rawFile = files[0] // only use files[0]
        const fileName = rawFile.name
        if (!rawFile) return
        this.importFilename(fileName)
        this.upload(rawFile)
    },
    upload(rawFile) {

      this.$refs['excel-upload-input'].value = null // fix can't select the same excel

      if(this.isImport) {
        if (!this.beforeUpload) {
          this.readerData(rawFile)
          return
        }
        const before = this.beforeUpload(rawFile)
        if (before) {
          this.readerData(rawFile)
        }
      } else {
        if (!this.beforeUpload) {
          this.readerSchedule(rawFile)
          return
        }
        const before = this.beforeUpload(rawFile)
        if (before) {
          this.readerSchedule(rawFile)
        }
      }
    },

    readerData(rawFile) {
      this.loading = true
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = e => {
          const data = e.target.result
          const fixedData = this.fixData(data)

          const workbook = XLSX.read(btoa(fixedData), { type: 'base64', cellDates: true, cellNF: false, cellText: false})
          const firstSheetName = workbook.SheetNames[0]
          const worksheet = workbook.Sheets[firstSheetName]
          const headers = this.getHeaderRow(worksheet)
          let header = []
          let results = []
          //check type file excel
          if(!this.checkHeaders(headers)){
            return this.readerEncodingData(data);
          }

          results = XLSX.utils.sheet_to_json(worksheet, {raw: false,dateNF:'yyyy/mm/dd'})
          results = this.convertFormatData(results)
          this.generateData({ header, results })
          resolve()
        }
        reader.readAsArrayBuffer(rawFile)
      })
    },
    readerSchedule(rawFile) {
      this.loading = true
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = e => {
          const data = e.target.result
          const fixedData = this.fixData(data)

          const workbook = XLSX.read(btoa(fixedData), { type: 'base64', cellDates: true, cellNF: false, cellText: false})
          const firstSheetName = workbook.SheetNames[0]
          const worksheet = workbook.Sheets[firstSheetName]
          const headers = this.getHeaderRow(worksheet)
          let header = []
          let results = []
          //check type file excel
          if(!this.checkHeaders(headers)){
            return this.readerEncodingSchedule(data);
          }

          results = XLSX.utils.sheet_to_json(worksheet, {raw: false,dateNF:'yyyy/mm/dd'})
          results = this.convertFormatData(results)
          this.generateData({ header, results })
          resolve()
        }
        reader.readAsArrayBuffer(rawFile)
      })
    },

    fixData(data) {
      let o = ''
      let l = 0
      const w = 10240
      for (; l < data.byteLength / w; ++l) o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w, l * w + w)))
      o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w)))
      return o
    },
    getHeaderRow(sheet) {
      const headers = []
      const range = XLSX.utils.decode_range(sheet['!ref'])
      let C
      const R = range.s.r
      /* start in the first row */
      for (C = range.s.c; C <= range.e.c; ++C) { /* walk every column in the range */
        const cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })]
        /* find the cell in the first row */
        let hdr = 'UNKNOWN ' + C // <-- replace with your desired default
        if (cell && cell.t) hdr = XLSX.utils.format_cell(cell)
        headers.push(hdr)
        //  else {
        //   this.errorArray.push({ column: hdr, position: (C + 1) })
        // }
      }
      return headers
    },


  readerEncodingData(data) {
      var codes = new Uint8Array(data);
      var encoding = Encoding.detect(codes);
      // Convert encoding to unicode
      var unicodeString = Encoding.convert(codes, {
          to: 'unicode',
          from: encoding,
          type: 'string'
      });
      let header = []
      let results = []
      if(!this.checkEncodingHeaders(unicodeString)){
          header = { 'error_header' : true }
          this.generateData({ header, results })
          this.loading = false
          return;
      }
      results = this.convertStrToJson(unicodeString)
      this.generateData({ header, results })
      this.loading = false
  },
  readerEncodingSchedule(data) {
      var codes = new Uint8Array(data);
      var encoding = Encoding.detect(codes);
      // Convert encoding to unicode
      var unicodeString = Encoding.convert(codes, {
          to: 'unicode',
          from: encoding,
          type: 'string'
      });
      let header = []
      let results = []
      if(!this.checkEncodingHeaders(unicodeString)){
          header = { 'error_header' : true }
          this.generateData({ header, results })
          this.loading = false
          return;
      }
      results = this.convertStrToJson(unicodeString)
      this.getString(unicodeString)
      this.generateData({ header, results })
      this.loading = false
  },

  convertStrToJson(csvText) {
      // Split all the text into seperate lines on new lines and carriage return feeds
      var allTextLines = csvText.split(/\r\n|\n/)

      var locations = []

      for (var i = 1; i < allTextLines.length; i++) {
          if(allTextLines[i]){
              var dataArr = allTextLines[i].split(/\t|,/)
              var location = {}
              dataArr.map((data, index) =>{
                  if(this.headerArray?.[index]){
                      location[this.headerArray[index].key] = data
                  }
              })
              locations.push(location)
          }
      }
      return locations
  },

  checkEncodingHeaders(csvText){
      var allTextLines = csvText.split(/\r\n|\n/)
      // Split per line on tabs and commas
      var headers = allTextLines[0].split(/\t|,/)
      let checkExits = true;

      if(headers.length !== this.headerArray.length){
          return false;
      }
      headers.map(h => {
          h = h.replace(/"/g, '');
          let findData = this.headerArray.find(header => header.label === h)
          if(!findData){
              checkExits = false
          }
      })
      return checkExits;
  },

  checkHeaders(headers){
      let checkExits = true;

      if(headers.length !== this.headerArray.length){
          return false;
      }

      headers.map(h => {
          let findData = this.headerArray.find(header => header.label === h)
          if(!findData){
              checkExits = false
          }
      })
      return checkExits;
  },

  convertFormatData(results){
    let data = [];

    results.map(r => {
        let d = {}
        this.headerArray.map(h => {
            d[h.key] =  r[h.label]
        })
        data.push(d)
    })
    return data;

  },
    isExcel(file) {
      return /\.(xlsx|xls|csv)$/.test(file.name)
    }
  }
}
</script>

<style scoped>
  .excel-upload-input{
    display: none;
  }
</style>
